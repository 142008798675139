import React, { useState } from "react";
import "./Product.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BalanceIcon from "@mui/icons-material/Balance";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Product = () => {
  const products = useSelector((state) => state.cart.products);

  const id = useParams().id;
  const [selectImage, setSelectImage] = useState("img");
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();

  const scrollDown = () => {
    window.scroll(0, 500);
  };

  const { data, loading, error } = useFetch(`/products/${id}?populate=*`);

  // console.log(data?.attributes?.max_order_qut);
  // console.log(products);

  const getQuantityInCart = () => {
    const cartItem = products.find((item) => item.id);
    return cartItem
      ? {
          id: cartItem.id,
          max_order_value: cartItem.max_order_qut,
          quantity: cartItem.quantity,
        }
      : { id: null, max_order_value: 2, quantity: 2 }; // Default values for quantity if no item is found
  };

  const cartData = getQuantityInCart();

  return (
    <div className="max-width">
      <div className="back_button">
        <a href="/products/1">
          {" "}
          <KeyboardBackspaceIcon /> Back to Shop
        </a>
      </div>
      <div className="product">
        {loading ? (
          "loading"
        ) : (
          <div className="product_card">
            <div className="left">
              <div className="images">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img?.data?.attributes?.url
                  }
                  alt=""
                  onClick={() => setSelectImage("img")}
                />
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.img2?.data?.attributes?.url
                  }
                  alt=""
                  onClick={() => setSelectImage("img2")}
                />
              </div>

              <div className="mainImg">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes[selectImage]?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
            </div>

            <div className="right">
              <h2>{data?.attributes?.title}</h2>
              {/* <p>{data?.id}</p> */}
              <span className="price">AED {data?.attributes?.price}</span>
              <h5>Weight - {data?.attributes?.weight}</h5>
              <p>{data?.attributes?.desc}</p>
              <div className="quantity">
                <button
                  onClick={() =>
                    setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                  }
                >
                  -
                </button>

                {quantity}

                {data?.attributes?.max_order_qut === 2 && quantity === 2 ? (
                  <span
                    style={{
                      backgroundColor: "yellow",
                      padding: "2px 3px",
                      fontSize: "12px",
                    }}
                  >
                    Only 2 pcs allowed{" "}
                  </span>
                ) : (
                  <button onClick={() => setQuantity((prev) => prev + 1)}>
                    +
                  </button>
                )}
              </div>
              {cartData.id === data?.id &&
              cartData.max_order_value === 2 &&
              data?.attributes?.max_order_qut === 2 &&
              cartData.quantity >= 2 ? (
                <div onClick={scrollDown}>
                  <div className="add">
                    <ToastContainer />
                    <AddShoppingCartIcon />
                    ADD TO CART
                  </div>
                </div>
              ) : (
                <div onClick={scrollDown}>
                  <div
                    className="add"
                    onClick={() => {
                      if (data?.attributes?.outofstock) {
                        toast.error("Temporary out of stock", {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          theme: "light",
                        });
                      } else if (
                        quantity < data?.attributes?.max_order_qut ||
                        data?.attributes?.max_order_qut !== 2
                      ) {
                        // Allows adding item to cart if max_order_qut limit is not reached or the item has a different max_order_qut
                        dispatch(
                          addToCart({
                            id: data.id,
                            title: data.attributes.title,
                            desc: data.attributes.desc,
                            weight: data.attributes.weight,
                            price: data.attributes.price,
                            img: data.attributes.img.data.attributes.url,
                            quantity,
                            max_order_qut: data.attributes.max_order_qut,
                          })
                        );
                      }
                    }}
                  >
                    <ToastContainer />
                    <AddShoppingCartIcon />
                    ADD TO CART
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <br />
        <br />
        <br />
        <div className="tranding">
          <h1>Top Selling Products</h1>
          <FeaturedProducts type="featured" />
        </div>
      </div>
    </div>
  );
};

export default Product;
