import React, { useState } from "react";
import "./Cart.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, resetCart, updateQuantity } from "../../redux/cartReducer";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Cart = () => {
  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const [isCheckout, setIsCheckout] = useState(false);

  // console.log(products);

  const delivaryAmout = 20;
  const delivaryAmoutLess = 10;
  const noDelivaryAmout = 0;

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => (total += item.quantity * item.price));
    return total.toFixed(2);
  };

  const netOder = Math.min(totalPrice());
  const withDelivaryCost = () => {
    if (netOder < 50) {
      const netTotal = netOder + delivaryAmout;
      return netTotal.toFixed(2);
    }
    if (netOder >= 50 && netOder < 75) {
      const netTotal = netOder + delivaryAmoutLess;
      return netTotal.toFixed(2);
    }
    if (netOder >= 75) {
      const netTotal = netOder + noDelivaryAmout;
      return netTotal.toFixed(2);
    } else {
      return;
    }
  };

  const finalCost = withDelivaryCost();
  let FinalNetPrice = withDelivaryCost();

  const massageDisplay_75 = () => {
    if (netOder < 75) {
      let value = 75 - netOder;
      return ` You're just AED ${value.toFixed(2)} away from free delivery! `;
    } else {
      return " 🥳  Woo.. hoo.. you're eligible for free delivery 🚚 ";
    }
  };

  const massgeDisplay_50 = () => {
    if (netOder < 50) {
      let valueTwo = 50 - netOder;
      return ` Or,Add just AED ${valueTwo.toFixed(
        2
      )} to slash your delivery fee to AED 10.`;
    } else {
      return;
    }
  };

  const comMessage = " 'Don't miss out—shop a little more and save big! ";
  const inital = netOder === 0 ? "hide" : "";

  const handleCheckout = () => {
    setIsCheckout(true);
  };

  return (
    <>
      {!isCheckout && (
        <div className="cart">
          <div className="cart_wrap" style={{ padding: "10px" }}>
            <h2 className="cart-head">Product in your cart</h2>

            {products?.map((item, i) => (
              <div className="item" key={i}>
                <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" />
                <div className="details">
                  <strong style={{ fontSize: "15px", fontWeight: "600" }}>
                    {item.title} - {item.weight}
                  </strong>

                  <p style={{ lineHeight: "1.2", fontSize: "12px" }}>
                    {item.desc?.substring(0, 20)}....
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "10px" }}>
                      Qty {item.quantity} x AED {item.price}
                    </span>
                    <div style={{ marginLeft: "20px" }}>
                      
                      {item.max_order_qut === 2 && item.quantity < 2 ? (
                        <button>-</button>
                      ) : (
                        <button
                          onClick={() =>
                            dispatch(
                              updateQuantity({
                                id: item.id,
                                quantity:
                                  item.quantity === 1 ? 1 : item.quantity - 1,
                              })
                            )
                          }
                        >
                          -
                        </button>
                      )}

                      <strong style={{ margin: "0px 10px", fontSize: "12px" }}>
                        {item.quantity}
                      </strong>

                      {item.max_order_qut === 2 && item.quantity > 1 ? (
                        <button>+</button>
                      ) : (
                        <button
                          onClick={() =>
                            dispatch(
                              updateQuantity({
                                id: item.id,
                                quantity: item.quantity + 1,
                              })
                            )
                          }
                        >
                          +
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <DeleteOutlinedIcon
                  className="delete"
                  onClick={() => dispatch(removeItem(item.id))}
                />
              </div>
            ))}

            <div className="total">
              <span>Products Total</span>
              <span>AED {totalPrice()}</span>
            </div>

            <div className="total">
              <span>Delivery Charges</span>
              {netOder < 50 ? (
                <p>AED 20.00</p>
              ) : netOder >= 50 && netOder < 75 ? (
                <p>AED 10.00</p>
              ) : (
                <p>Free</p>
              )}
            </div>

            <div className="sub_total">
              <span>SUBTOTAL</span>
              <div className={`${netOder === 0 ? "hide" : ""}`}>
                <span>AED {FinalNetPrice}</span>
              </div>
            </div>
            <div className="delivaryMsg">
              <span style={{ fontWeight: 400 }}>
                {massageDisplay_75()}
                {massgeDisplay_50()}
                Don't miss out—shop a little more and save big!
              </span>
            </div>
            <Link
              to={{
                pathname: "/check_out",
                state: { products, finalCost },
              }}
            >
              <button className="checkout" onClick={handleCheckout}>
                PROCEED TO CHECKOUT
              </button>
            </Link>
            <span className="reset" onClick={() => dispatch(resetCart())}>
              Reset Cart
            </span>
            {/* <div className="freeDel">
              <span>
                <strong>*</strong> Free home delivery on orders over{" "}
                <strong>AED 75</strong> —no extra fees, just great value! <br />{" "}
                <strong>*</strong>For orders between <strong>AED 50</strong> and
                <strong>AED 75</strong>, enjoy delivery for only{" "}
                <strong>AED 10</strong>. <br /> <strong>*</strong> Orders below
                <strong>AED 50</strong> incur a delivery charge of{" "}
                <strong>AED 20</strong>.
              </span>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
