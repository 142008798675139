import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./payment.scss";
import { useDispatch, useSelector } from "react-redux";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import Succsess from "../../components/payment/Succsess";
import CheckoutForm from "../../components/checkOut/CheckoutForm";
import { FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { updateQuantity } from "../../redux/cartReducer";

const Payment = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("pending");
  const [qrCode, setQrCode] = useState(null);
  const dispatch = useDispatch();
  const [varifyMail, setVarifyMail] = useState(false);

  const navigate = useNavigate();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [
    destination_address_mobile_number,
    setDestination_address_mobile_number,
  ] = useState("");

  const [destination_address_house_no, setDestination_address_house_no] =
    useState("");
  const [destination_address_area, setDestination_address_area] = useState("");
  const [destination_address_landmark, setDestination_address_landmark] =
    useState("");
  const [destination_address_city, setDestination_address_city] = useState("");

  const [customer_reference_number, setCustomer_reference_number] =
    useState("");

  const [showCheckout, setShowCheckout] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sessionId, setSessionId] = useState(null);

  const generateUniqueNumber = () => {
    const uniqueNumber = "NOT-" + Date.now();
    setCustomer_reference_number(uniqueNumber);
  };

  // const devApiLink = "http://localhost:4047/api/v1";

  const devApiLink = "https://chocodate-api.notionspromos.com/api/v1";

  // console.log(varifyMail);

  useEffect(() => {
    if (!qrCode) {
      return;
    }

    const getVarifyCode = async () => {
      setLoading(true);
      try {
        await axios
          .get(`${devApiLink}/varifyCode/${qrCode}`)
          .then((response) => {
            const isCodeValid = response.data.code;
            setApiResponse(isCodeValid);
          });
      } catch (error) {
        setError("Code not valid or Already Used");
      } finally {
        setLoading(false);
      }
    };

    getVarifyCode();
  }, [qrCode]);

  const statusCode = () => {
    if (apiResponse?.qr) {
      return <FaCheckCircle color="#39e75f" />;
    }
    if (qrCode === null && !apiResponse?.qr) {
      return;
    }
    if (!apiResponse?.qr) {
      return (
        <div
          style={{
            padding: "5px 10px",
            backgroundColor: "red",
            color: "#00000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <strong
            style={{
              fontSize: "12px",
              color: "white",
              fontWeight: 600,
            }}
          >
            wrong code or code already used
          </strong>
        </div>
      );
    }
    if (loading) {
      return <small>Loading... </small>;
    } else {
      return;
    }
    return null; // Ensures the function always returns something, even if no conditions are met
  };

  const gift = () => {
    if (
      apiResponse?.winItemName === "Mobile Phone" ||
      apiResponse?.winItemName === "GOLD COIN" ||
      apiResponse?.winItemName === "2 Movie Ticket" ||
      apiResponse?.winItemName === "1 Free Family pack"
    ) {
      return (
        <span style={{ fontSize: "13px" }}>
          Contact support@notionsgroup.com to claim your prize!
        </span>
      );
    }

    if (apiResponse?.winItemName === "50% Off on next order") {
      return <span>Enjoy 50% off your total purchase!</span>;
    }

    // Default return when none of the above conditions match
    return <span>{apiResponse?.winItemName}</span>;
  };

  useEffect(() => {
    generateUniqueNumber();
  }, []);

  useEffect(() => {
    if (!email) {
      return;
    } else {
      const checkMail = apiResponse?.userEmail === email;
      setVarifyMail(checkMail);
    }
  }, [email]);

  const products = useSelector((state) => state.cart.products);

  const delivaryAmout = 20;
  const delivaryAmoutLess = 10;
  const noDelivaryAmout = 0;

  const totals = products.reduce(
    (acc, product) => {
      const price = parseFloat(product.price);
      const quantity = parseInt(product.quantity, 10);

      acc.totalPrice += price * quantity;
      acc.totalItems += quantity;

      return acc;
    },
    { totalPrice: 0, totalItems: 0 }
  );

  const netOderBefore = totals.totalPrice;

  // console.log("netOderBefore",netOderBefore);

  const addCoupon = () => {
    if (!apiResponse?.winItemName) {
      return;
    }
    if (
      apiResponse?.winItemName === "50% Off on next order" &&
      varifyMail === true
    ) {
      const value = netOderBefore / 2;
      return value;
    } else {
      return;
    }
  };

  const addCouponValue = parseInt(addCoupon());

  // console.log("addCouponValue",addCouponValue);

  const finalVale = () => {
    if (!addCouponValue) {
      return netOderBefore;
    } else {
      const couponValue = netOderBefore - Number(addCouponValue);
      return couponValue;
    }
  };

  const netOder = finalVale();

  const withDelivaryCost = () => {
    if (netOder < 50) {
      const netTotal = netOder + delivaryAmout;
      return netTotal?.toFixed(2);
    }
    if (netOder >= 50 && netOder < 75) {
      const netTotal = netOder + delivaryAmoutLess;
      return netTotal?.toFixed(2);
    }
    if (netOder >= 75) {
      const netTotal = netOder + noDelivaryAmout;
      return netTotal?.toFixed(2);
    } else {
      return;
    }
  };

  const deliveryCost = () => {
    if (netOder < 50) {
      return 20;
    } else if (netOder >= 50 && netOder < 75) {
      return 10;
    } else {
      return 0;
    }
  };
  const delivery_cost = deliveryCost();

  const finalCost = withDelivaryCost();

  const productsData = {
    totals: finalCost,
    qut: totals.totalItems,
    fName,
    email,
    price: finalCost,
    name: fName,
    title: fName,
    quantity: totals.totalItems,
  };

  const placeOrder = async (e) => {
    e?.preventDefault();
    const orderData = {
      destination_address_name: fName + " " + lName,
      email,
      products,
      brand: "snacksfactory",
      num_pieces: productsData.qut,
      card_amount: productsData.totals,
      descripon: products.title,
      destination_address_city,
      destination_address_landmark,
      destination_address_area,
      destination_address_building_name: destination_address_house_no,
      destination_address_house_no,
      customer_reference_number,
      destination_address_mobile_number,
      weight: 2,
      delivery_cost,
    };

    localStorage.setItem("orderData", JSON.stringify(orderData));
  };

  const showCheckoutSection = (e) => {
    e.preventDefault();
    setShowCheckout(true);
    placeOrder();
  };

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const sessionId = urlParams.get("session_id");
  //   setSessionId(sessionId);
  // }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    if (!sessionId) {
      return;
    }
    try {
      axios.get(`/session-status?session_id=${sessionId}`).then((response) => {
        const data = response.data;
        setStatus(data.status);
      });
    } catch (error) {
      console.log(error);
    }
  }, [sessionId]);

  // if (status === "complete") {
  //   placeOrder();
  // }

  useEffect(() => {
    const updateOrder = async () => {
      if (status !== "complete") {
        return;
      }
      const savedData = localStorage.getItem("orderData");
      if (savedData) {
        const orderData = JSON.parse(savedData);
        try {
          if (status == "complete") {
            await axios.post("/create_order", orderData);
            navigate("/");
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    updateOrder();
  }, [status]);

  // const massageDisplay = () => {
  //   if (netOder < 50) {
  //     let valueTwo = 50 - netOder;
  //     return `Add just AED ${valueTwo.toFixed(
  //       2
  //     )} more to reduce your delivery fee to AED 10.`;
  //   } else if (netOder < 75) {
  //     let value = 75 - netOder;
  //     return `You're just AED ${value.toFixed(
  //       2
  //     )} away from free delivery! Don't miss out—shop a little more and save big!`;
  //   } else {
  //     return "🥳  Woo.. hoo.. you're eligible for free delivery 🚚 ";
  //   }
  // };
  const massageDisplay_75 = () => {
    if (netOder < 75) {
      let value = 75 - netOder;
      return ` You're just AED ${value.toFixed(2)} away from free delivery! `;
    } else {
      return " 🥳  Woo.. hoo.. you're eligible for free delivery 🚚 ";
    }
  };

  const massgeDisplay_50 = () => {
    if (netOder < 50) {
      let valueTwo = 50 - netOder;
      return `  Or,Add just AED ${valueTwo.toFixed(
        2
      )} to slash your delivery fee to AED 10. `;
    } else {
      return;
    }
  };

  const inital = netOder === 0 ? "hide" : "";

  return (
    <div className="max-width">
      {status !== "complete" ? (
        <>
          <div className="payment">
            <div className="pay_info">
              <div className="pro_list">
                <div>
                  <h5>Order summary</h5>
                </div>
                {products.length > 0 ? (
                  <div className="all_pro_list">
                    {products.map((product, index) => (
                      <div className="pro_card" key={index}>
                        <div className="pro_card_in">
                          <div className="img_wrp">
                            <img
                              src={
                                process.env.REACT_APP_UPLOAD_URL + product.img
                              }
                              alt=""
                            />
                          </div>
                          <div className="info_wrp">
                            <span>
                              <em>
                                {product.title} {product.weight}
                              </em>
                            </span>
                          </div>
                          <div className="info_wrp">
                            <span>
                              <em>Qut:</em> {product.quantity}
                            </span>
                          </div>
                          <div className="info_wrp">
                            <span>
                              <em>AED </em>{" "}
                              {(product.price * product.quantity).toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <button
                              onClick={() =>
                                dispatch(
                                  updateQuantity({
                                    id: product.id,
                                    quantity:
                                      product.quantity === 1
                                        ? 1
                                        : product.quantity - 1,
                                  })
                                )
                              }
                            >
                              -
                            </button>
                            <strong
                              style={{ margin: "0px 20px", fontSize: "12px" }}
                            >
                              {product.quantity}
                            </strong>

                            {product.max_order_qut === 2 &&
                            product.quantity === 2 ? (
                              <></>
                            ) : (
                              <button
                                onClick={() =>
                                  dispatch(
                                    updateQuantity({
                                      id: product.id,
                                      quantity: product.quantity + 1,
                                    })
                                  )
                                }
                              >
                                +
                              </button>
                            )}
                          </div>
                        </div>
                        {/* <div className="pro_des">
                          <em>{product.desc}</em>
                        </div> */}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No products in the cart</p>
                )}
                <div className="total_wrp bg">
                  <span>Have coupon ? </span>
                  <span>{statusCode()}</span>
                  <input
                    value={qrCode}
                    onChange={(e) => setQrCode(e.target.value)}
                    placeholder="ABC123"
                    type="text"
                  />
                </div>
                {!apiResponse ? (
                  <></>
                ) : (
                  <>
                    <div
                      style={{ backgroundColor: "rgb(57, 231, 95)" }}
                      className="total_wrp"
                    >
                      <span style={{ color: "white", fontWeight: "600" }}>
                        {gift()}
                      </span>
                      <em
                        style={{
                          fontStyle: "normal",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        {!varifyMail &&
                          "Alos use your registered email on Always a winner! to get exclusive discounts!"}
                      </em>
                    </div>
                  </>
                )}
                <div className="total_wrp">
                  <span>Items Total</span>
                  <span>AED {netOderBefore?.toFixed(2)}</span>
                </div>
                {apiResponse && (
                  <>
                    {varifyMail && (
                      <>
                        <div className="total_wrp">
                          <span>Discount value </span>
                          <span style={{ color: "red" }}>
                            AED ( {addCouponValue?.toFixed(2)} )
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
                {varifyMail === true && (
                  <div className="total_wrp">
                    <span>Sub Total</span>
                    <span>AED {netOder.toFixed(2)}</span>
                  </div>
                )}

                <div className="total_wrp">
                  <span>Delivery Charges</span>
                  <span>AED {delivery_cost.toFixed(2)}</span>
                </div>
                <hr />
                <div className="total_wrp">
                  <h4>Total</h4> <h4>AED {withDelivaryCost()}</h4>{" "}
                </div>
              </div>
              <div className="msgShow">
                <span style={{ fontWeight: 400 }}>
                  {massageDisplay_75()}
                  {massgeDisplay_50()}
                  Don't miss out—shop a little more and save big!
                </span>
              </div>

              <div className="check_form">
                <div>
                  <strong>Customer details</strong>
                </div>
                <form onSubmit={showCheckoutSection}>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>First name</label>
                      <input
                        required
                        type="text"
                        value={fName}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Last name</label>
                      <input
                        required
                        type="text"
                        value={lName}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>Email</label>
                      <input
                        required
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Phone Number</label>
                      <input
                        required
                        value={destination_address_mobile_number}
                        type="text"
                        onChange={(e) =>
                          setDestination_address_mobile_number(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>City</label>

                      <select
                        name="city"
                        class="form-control save_data"
                        id="city"
                        value={destination_address_city}
                        required="required"
                        onChange={(e) =>
                          setDestination_address_city(e.target.value)
                        }
                      >
                        <option>Select Your City </option>
                        <option value="Abu Dhabi">Abu Dhabi</option>
                        <option value="Dubai">Dubai</option>
                        <option value="Sharjah">Sharjah</option>
                        <option value="Ajman">Ajman</option>
                        <option value="Umm Al-Quwain">Umm Al-Quwain</option>
                        <option value="Fujairah">Fujairah</option>
                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                        <option value="Al-Ain">Al-Ain</option>{" "}
                      </select>
                    </div>

                    <div className="input_wrp">
                      <label>Address</label>
                      <input
                        required
                        type="text"
                        value={destination_address_area}
                        onChange={(e) =>
                          setDestination_address_area(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="from_wrp">
                    <div className="input_wrp">
                      <label>House No. or Name</label>
                      <input
                        required
                        type="text"
                        value={destination_address_house_no}
                        onChange={(e) =>
                          setDestination_address_house_no(e.target.value)
                        }
                      />
                    </div>
                    <div className="input_wrp">
                      <label>Landmark</label>
                      <input
                        required
                        type="text"
                        value={destination_address_landmark}
                        onChange={(e) =>
                          setDestination_address_landmark(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="pay_btn">
                    <button className="checkout">Continue</button>
                  </div>
                  <small style={{ color: "#FF0000", fontSize: "14px" }}>
                    <IoIosWarning color="red" /> Note: Please do not close this
                    page until your order is confirmed.
                  </small>
                </form>
              </div>
            </div>
            <div className="pay_card">
              {showCheckout && <CheckoutForm productsData={productsData} />}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <Succsess />
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
