import React from 'react'
import './slideShow.scss'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from 'react-router-dom';



const SlideShow = () => {
    

    const options = {
      loop: true,
      responsiveClass: true,
      center: true,
      items: 1,
      margin: 0,
      autoplay: true,
      dots: true,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };


  return (
    <div className="">
      <Link to={"/offers"}>
        <div className="slideShow ">
          <OwlCarousel className="owl-theme" {...options}>
            <div class="item">
              <img src={require("../../img/o6.jpg")} />
            </div>
            <div class="item">
              <img src={require("../../img/o4.jpg")} />
            </div>
            <div class="item">
              <img src={require("../../img/o2.jpg")} />
            </div>
            <div class="item">
              <img src={require("../../img/o5.jpg")} />
            </div>
          </OwlCarousel>
        </div>
      </Link>
    </div>
  );
}

export default SlideShow
