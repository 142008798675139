import React from 'react'
import './googleMap.scss'

const GoogleMap = () => {
  return (
    <div className="map">
      <h1>Visit our factory outlet</h1>
      <div className="map_wrap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14465.69199800478!2d55.1882209!3d24.9857387!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ca6c390eaf7%3A0x27ef84f509b385b9!2sNotions%20Group!5e0!3m2!1sen!2sae!4v1698055153572!5m2!1sen!2sae"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default GoogleMap
